import G3011 from './icon_map_G3011.png'
import G30 from './icon_map_G30.png'
import G6 from './icon_map_G6.png'
import G7 from './icon_map_G7.png'
import G22 from './icon_map_G22.png'
import G69 from './icon_map_G69.png'
import G70 from './icon_map_G70.png'
import G75 from './icon_map_G75.png'
import G316 from './icon_map_G316.png'
import G2012 from './icon_map_G2012.png'
import G7011 from './icon_map_G7011.png'
import G0611 from './icon_map_G0611.png'
// import G7 from './icon_map_G7.png'


export const ROAD_SIGNS =  [
    {sign: 'G3011', lng: 94.560734, lat: 37.930715, src: G3011},
    {sign: 'G30', lng: 94.938884, lat: 41.889553, src: G30},
    {sign: 'G30', lng: 107.61623, lat: 34.366278, src: G30},
    {sign: 'G6', lng: 102.421781, lat: 36.102587, src: G6},
    {sign: 'G6', lng: 105.982436, lat: 36.945506, src: G6},
    {sign: 'G7', lng: 95.709489, lat: 42.289036, src: G7},
    {sign: 'G7', lng: 98.355388, lat: 41.920276, src: G7},
    {sign: 'G22', lng: 109.499642, lat: 36.118121, src: G22},
    {sign: 'G69', lng: 109.37292, lat: 35.001616, src: G69},
    {sign: 'G70', lng: 108.478346, lat: 34.744917, src: G70},
    {sign: 'G75', lng: 105.821668, lat: 32.13073, src: G75},
    {sign: 'G316', lng: 107.401745, lat: 33.7807, src: G316},
    {sign: 'G2012', lng: 105.202394, lat: 37.595257, src: G2012},
    {sign: 'G7011', lng: 106.745512, lat: 33.012788, src: G7011},
    {sign: 'G0611', lng: 100.898369, lat: 37.547146, src: G0611},
    // {sign: 'G227', lng: 98.455388, lat: 42.020276, src: G227},
]


export const HEAT_MAP_ROAD_SIGNS =  [
    {sign: 'G3011', lng: 94.560734, lat: 37.930715, src: G3011},
    {sign: 'G30', lng: 94.938884, lat: 41.889553, src: G30},
    {sign: 'G30', lng: 107.61623, lat: 34.366278, src: G30},
    {sign: 'G6', lng: 102.421781, lat: 36.102587, src: G6},
    {sign: 'G6', lng: 105.982436, lat: 36.945506, src: G6},
    {sign: 'G7', lng: 95.709489, lat: 42.289036, src: G7},
    {sign: 'G7', lng: 98.355388, lat: 41.920276, src: G7},
    {sign: 'G22', lng: 109.499642, lat: 36.118121, src: G22},
    {sign: 'G69', lng: 109.37292, lat: 35.001616, src: G69},
    {sign: 'G70', lng: 108.418346, lat: 34.744917, src: G70},
    {sign: 'G75', lng: 105.941668, lat: 32.38073, src: G75},
    {sign: 'G316', lng: 107.401745, lat: 33.7807, src: G316},
    {sign: 'G2012', lng: 105.202394, lat: 37.595257, src: G2012},
    {sign: 'G7011', lng: 106.745512, lat: 33.012788, src: G7011},
    {sign: 'G0611', lng: 100.898369, lat: 37.547146, src: G0611},
    // {sign: 'G227', lng: 98.455388, lat: 42.020276, src: G227},
]


































