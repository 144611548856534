//高德地图工具类

const ampUtil={
    /**
     * 创建地图实例
     * @param {*} mapId dom元素的id
     * @param {*} lnglat 经纬度[经度,纬度]
     * @param {*} mapStyle 地图样式
     * @return 返回地图实例
     */
    createMap :function (mapId, lnglat, mapStyle){
        let map =new AMap.Map(mapId,{
            zoom:6,
            zooms: [6,16],
            center:lnglat,
            layers: [new AMap.TileLayer.Satellite(),  // 卫星
                        new AMap.TileLayer.RoadNet() // 路网
                    ],
            mapStyle: mapStyle, //设置地图的显示样式
        });
        return map;
    },
    // 创建智能分析热力地图
    createAnalyseMap: function (mapId, lnglat, mapStyle){
        let map =new AMap.Map(mapId,{
            zoom:6,
            rotation: -10,
            // pitch: 20, // 地图俯仰角度，有效范围 0 度- 83 度
            // viewMode:'3D', // 地图模式
            center:lnglat,
            mapStyle: mapStyle, //设置地图的显示样式
            zoomEnable: false, //地图是否可缩放，默认值为true
            rotateEnable: false,
            dragEnable: false, // 地图是否可通过鼠标拖拽平移，默认为true
        });
        return map;
    },
    // 创建重点路段地图
    createRoadMap: function (mapId, lnglat, mapStyle){
        let map =new AMap.Map(mapId,{
            zoom:6,
            zooms: [6,16],
            // rotation: -10,
            // pitch: 20, // 地图俯仰角度，有效范围 0 度- 83 度
            // viewMode:'3D', // 地图模式
            center:lnglat,
            mapStyle: mapStyle, //设置地图的显示样式
            rotateEnable: false
        });
        return map;
    },
    /**
     * 创建实时路况图层
     * @param map 地图实例
     * @return {AMap.TileLayer.Traffic}
     */
     createTraffic:function (){
        //实时路况图层
        let trafficLayer = new AMap.TileLayer.Traffic({
            zIndex: 10
        });
        return trafficLayer;
    },
    /**
     * 地图查询行政区域
     * @param {*} districtSearchOptions 查询参数详见https://lbs.amap.com/api/javascript-api/reference/search/#m_AMap.DistrictSearch
     * @param {*} keywords 查询关键字
     * @param {*} callBack 回调函数
     */
    mapDistrictSearch:function (districtSearchOptions,keywords,callBack) {

        AMap.service('AMap.DistrictSearch',()=>{//回调函数
            let districtSearch = new AMap.DistrictSearch(districtSearchOptions)
            districtSearch.search(keywords,(status, result)=>{
                callBack(status, result)
            })
        })
    },
    /**
     * 隐藏查询结果以外的地图区域
     */
    hideMapOtherAreasBySearch:function (keywords,map){
        this.mapDistrictSearch({
            // 返回行政区边界坐标等具体信息
            extensions: 'all',
            // 设置查询行政区级别为 省
            level: 'province'
        },keywords,(status, result)=>{
            this.hideMapOtherAreas(status,result,map)
        })
    },
    /**
     * 隐藏地图上其他的区域
     * @param status
     * @param result
     */
    hideMapOtherAreas:function (status, result,map){
        let outer = [
            new AMap.LngLat(-360,90,true),
            new AMap.LngLat(-360,-90,true),
            new AMap.LngLat(360,-90,true),
            new AMap.LngLat(360,90,true),
        ];
        let holes = result.districtList[0].boundaries
        var pathArray = [
            outer
        ];
        pathArray.push.apply(pathArray,holes)
        let polygon = new AMap.Polygon( {
            pathL:pathArray,
            //线条颜色，使用16进制颜色代码赋值。默认值为#006600
            strokeColor: 'rgb(20,164,173)',
            strokeWeight: 2,
            //轮廓线透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            strokeOpacity:0.5,
            //多边形填充颜色，使用16进制颜色代码赋值，如：#FFAA00
            fillColor: '#080B45',
            //多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            fillOpacity: 0.6,
            //轮廓线样式，实线:solid，虚线:dashed
            strokeStyle:'solid',
            /*勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在
              ie9+浏览器有效 取值：
              实线：[0,0,0]
              虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
              点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实
              线和10个像素的空白 （如此反复）组成的虚线*/
            strokeDasharray:[10,2,10]
        });
        polygon.setPath(pathArray);
        polygon.setPath(pathArray);
        map.add(polygon);
    },
    /**
     * 创建聚合完全自定义聚合样式
     * @param map 地图实例
     * @param markers 点位集合
     * @param gridSize 聚合范围
     * @param renderClusterMarker 聚合样式（参考：https://lbs.amap.com/api/javascript-api/example/marker/markerclusterer/）
     * @param callBack 回调函数返回MarkerClusterer
     */
    createMarkerClustererMarker:function (map,markers,gridSize,renderClusterMarker,callBack) {

        AMap.service('AMap.MarkerClusterer',()=>{//回调函数
            let cluster = new AMap.MarkerClusterer(map, markers, {
                gridSize: gridSize,
                renderClusterMarker:renderClusterMarker
            })
            callBack(cluster)
        })
    },
    /**
     * 创建聚合自定义图标模式
     * @param map 地图实例
     * @param markers 点位集合
     * @param gridSize 聚合范围
     * @param styles 图标样式（参考：https://lbs.amap.com/api/javascript-api/example/marker/markerclusterer/）
     * @param callBack 回调函数返回MarkerClusterer
     */
    createMarkerClustererStyle:function (map,markers,gridSize,styles,callBack) {
        AMap.service('AMap.MarkerClusterer',()=>{//回调函数
            let cluster = new AMap.MarkerClusterer(map, markers, {
                styles: styles,
                gridSize: gridSize
            })
            callBack(cluster)
        })
    },
    /**
     * 将带/的经纬度字符串分割为[经度,纬度]的格式
     * @param lnglatStr 带/的经纬度字符串
     * @return {[]} [经度,纬度]的格式
     */
    splitlngLat:function (lnglatStr) {
        let postion = []
        if (lnglatStr) {
            postion = lnglatStr.split('/')
        }
        return postion
    },
    /**
     * 创建标注点marker对象携带属性dataSource为传入的数据
     * @param dataSource 数据源
     * @param lnglat 经纬度[经度,纬度]
     * @param offset 图片偏移量(根据图片大小计算)
     * @param iconOptions ampICon参数{size: new AMap.Size(),imageSize: new AMap.Size(),image:  }
     * @param clickEvent 点击事件绑定
     * @param mouseOverEvent 鼠标悬浮事件绑定
     * @return {AMap.Marker}
     */
    createMarker:function (dataSource,lnglat,iconOptions,clickEventBack,mouseOverEventBack,offset=new AMap.Pixel(0, 0)){
        let marker = new AMap.Marker({
            position: lnglat,
            icon: new AMap.Icon(iconOptions),
            draggable: false,
            cursor: 'pointer',
            zIndex: 999,
            topWhenClick: true,
            clickable: true,
            offset: offset,
        });
        marker.dataSource= dataSource
        marker.dataPosition= lnglat
        if(clickEventBack!=null){
            marker.on('click', (clickEvent)=>{
                clickEventBack(clickEvent)
            })
        }
        if(mouseOverEventBack!=null){
            marker.on('mouseover', (mouseOverEvent)=>{
                mouseOverEventBack(mouseOverEvent)
            })
        }

        return marker;
    },
    /**
     * 创建OverlayGroup
     * @param markers 需要创建的标记点的集合
     * @return {AMap.OverlayGroup}
     */
    createOverlayGroup:function (markers){
        let group = new AMap.OverlayGroup(markers)
        return group
    },
    /**
     * 给地图绑定事件
     * @param map 地图实例
     * @param eventName 事件名称
     * @param eventFun 事件函数
     */
    bindMapEvent:function (map,eventName,eventFun,){
        map.on(eventName, eventFun);
    }


}
export default ampUtil

