/**
 *
 * @type {number}
 */
const navigatorHeignt = 72;

/**
 * 获取没有导航条的页面高度
 * @returns 没有导航条的页面高度
 */
export function getNoExistNavigatorBodyHeignt () {
    let winHeight;
    if (window.innerHeight){
        winHeight = window.innerHeight;
    }else if ((document.body) && (document.body.clientHeight)){
        winHeight = document.body.clientHeight;
    }
    return winHeight;
}
export function getBodyWidth(){
    let width;
    if (window.innerWidth){
        width = window.innerWidth;
    }else if ((document.body) && (document.body.clientWidth)){
        width = document.body.clientWidth;
    }
    return width;
}
/**
 * 获取有导航条的页面高度
 * @returns 有导航条的页面高度
 */
export function getExistNavigatorBodyHeignt () {
    let winHeight;
    if (window.innerHeight){
        winHeight = window.innerHeight;
    }else if ((document.body) && (document.body.clientHeight)){
        winHeight = document.body.clientHeight;
    }
    winHeight = winHeight - navigatorHeignt;
    return winHeight;
}
